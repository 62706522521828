<script>
export default {
  data() {
    return {
      pricingData: [
        {
          title: "Knowledgebase",
          price: 0,
          feature: ["Members resource", "Free consultation", ],
          button: "Unlock Now",
        },
        {
          title: "Starter Try",
          price: 6,
          feature: ["One single task", "1 day 2 hours", "Premium access",],
          button: "Try it Now",
          isBest: true,
        },
        {
          title: "Professional",
          price: 59,
          feature: [
            "Any 6 days plan",
            "7 task equivalent",
            "2 hours block/day",
            "Premium access",
          ],
          button: "Get Started",
        },
        {
          title: "Ultimate",
          price: 197,
          feature: [
            "Any 15 days plan",
            "20 task equivalent",
            "2 hours block/day",
            "Instant metting",
            "Premium access",

          ],
          button: "OnBoard Now",
        },
      ],
    };
  },
};
</script>

<template>
  <div class="row">
    <div
      class="col-lg-3 col-md-6 col-12 mt-4 pt-2"
      v-for="(item, index) of pricingData"
      :key="index" >
      <div
        class="card pricing-rates business-rate shadow bg-light border-0 rounded"
        :class="{
          'bg-white': item.isBest === true,
          'bg-light': item.isBest !== true,
        }"
      >
        <div
          class="ribbon ribbon-right ribbon-warning overflow-hidden"
          v-if="item.isBest"
        >
          <span class="text-center d-block shadow small h6">Best</span>
        </div>
        <div class="card-body">
          <h6 class="title font-weight-bold text-uppercase text-primary mb-4">
            {{ item.title }}
          </h6>
          <div class="d-flex mb-4">
            <span class="h4 mb-0 mt-2">$</span>
            <span class="price h1 mb-0">{{ item.price }}</span>
            <span class="h4 align-self-end mb-1">/ task</span>
          </div>

          <ul class="list-unstyled mb-0 pl-0">
            <li
              v-for="(feature, index) in item.feature"
              class="h6 text-muted mb-0"
              :key="index"
            >
              <span class="text-primary h5 mr-2"
                ><i class="uil uil-check-circle align-middle"></i></span
              >{{ feature }}
            </li>
          </ul>
          <a href="javascript:void(0)" class="btn btn-primary mt-4">{{
            item.button
          }}</a>
        </div>
      </div>
    </div>
    <!--end col-->
  </div>
</template>