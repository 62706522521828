<script>
import {
  ArrowUpIcon,
  ArrowRightIcon,
  ArrowRightCircleIcon,
  HelpCircleIcon,
  PhoneIcon,
  VideoIcon,
  MailIcon,
    YoutubeIcon,
    MapPinIcon,
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    GithubIcon,
    GitlabIcon
} from "vue-feather-icons";

import Navbar from "@/components/navbar";
import Footer from "@/components/footer";
import Pricing from "@/components/pricing";
import countTo from "vue-count-to";
import { Carousel, Slide } from "vue-carousel";


{
  metaInfo: {
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'description',
        name: 'description',
        content: 'Parent description.'
      }
    ]
  }
}


/**
 * Index task-management component
 */


export default {
  data() {
    return {
    }
  },
  components: {
    Navbar,
    Carousel,
    Slide,
    Footer,
    MapPinIcon,
    PhoneIcon,
    Pricing,
    ArrowUpIcon,
    ArrowRightIcon,
    ArrowRightCircleIcon,
    HelpCircleIcon,
    VideoIcon,
    countTo,


    MailIcon,
        FacebookIcon,
        InstagramIcon,
        TwitterIcon,
        LinkedinIcon,
        GithubIcon,
        YoutubeIcon,
        GitlabIcon
  },


      metaInfo() {
        return { 
            title: "Explore Karukaj",
            meta: [
                { name: 'description', content:  '"KD Members Portal" is the vitual headquarters of Karukaj DIGITAL, where "KD Squad" conducts simple and artistic activities to known as the best digital marketing agency in Bangladesh.'},
                { property: 'og:title', content: "Karukaj - Build your network in your field of interest"},
                { property: 'og:site_name', content: 'Epiloge'},
                {property: 'og:type', content: 'website'},    
                {name: 'robots', content: 'index,follow'} 
            ]
        }
    }
};




</script>





<template>
 
 
  <div>
    <Navbar :nav-light="false" />
    <!-- Start Home -->





    <!-- Hero Start -->
    <section class="bg-half d-table w-100 soft-sky-bg sss">






        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-12 text-center">
                    <div class="my-custom-title2">
                        <h4 class=" text-white title-dark mt-100 mb-4">
                    
            
                          
                        Get our digital marketing services<br>anytime and anywhere</h4>
                        <div class="pt-2">
           




                           <div class="alert alert-outline-primary alert-pills" role="alert">
<span class="badge badge-pill badge-danger">  </span>   <span class="alert-content my-color-1 font-weight-bold ">WE OFFER ORGANIZED WORK ONLINE</span>
</div>


                        </div>

<br>

                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <div class="position-relative">
        <div class="shape overflow-hidden text-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
    <!-- Hero End -->

   <section class="section">


      <div class="container mt-40">

<br>


<div class="row">
  <div class="col-lg-6">
    <div class="section-title text-center text-lg-left">


      <h4 class="title mb-4"><b>Our Creativity  </b>
                <span class="my-color-7 ">  Is Your Success </span> 
              </h4>





      <p class="responsive-justify text-muted mb-0 mx-auto para-desc"> 
        <span v-b-tooltip.hover title="Even-though Karukaj does outsourcing in Bangladesh and 
        the most popular as the best eCommerce website development company in Bangladesh, who have custom design expertise as a bset web development company in Bangladesh." class="text-dark">Karukaj DIGITAL</span>
        <span v-b-tooltip.hover title="best digital marketing agency"></span> 
        is now a <b>fastest growing social media</b> advertising, <b>seo marketing service</b>, and
        
        <span v-b-tooltip.hover title="Karukaj 
        specially trained portfolio web developers and able to create any type of portfolio website design, 
        catalog website design, ecommerce website design or personal website design." ><b>best web design</b></span>

        
        
        or web development company in Bangladesh. 
        We believe the power of the internet is now at your fingertips, and with us, you’ll be doing great things in a few days. 
        
        
        Apart from service of digital marketing agency in Bnagladesh, we also operate business with other <b>like-minded businesses</b> besides running freelance outsourcing business and e-commerce business online.

    
      </p>


<br/>

      <div class="col-12 text-md-right">


<a href="javascript:void(0)" class="my-color-1 h6"><span v-b-tooltip.hover title="
  You can message us to see our portfolio of designed work, before starting contact." 
>You may Ask to see anytime..</span> <arrow-right-icon class="fea icon-sm"></arrow-right-icon></a>
</div>



    </div>
  </div>
  <!--end col-->

  <div class="col-lg-6">
    <div class="row" id="counter">
      <div class="col-md-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
        <div class="content text-center">
          <h1 class="mb-0">
            <countTo
              :startVal="1"
              :duration="6000"
              :endVal="300"
            ></countTo
            >+
          </h1>
          <ul class="list-unstyled mb-0 h5">
            <li class="list-inline-item">
              <i class="mdi mdi-heart lovecolor"></i>
            </li>
            <li class="list-inline-item">
              <i class="mdi mdi-heart lovecolor"></i>
            </li>
            <li class="list-inline-item">
              <i class="mdi mdi-heart lovecolor"></i>
            </li>
            <li class="list-inline-item">
              <i class="mdi mdi-heart lovecolor"></i>
            </li>
            <li class="list-inline-item">
              <i class="mdi mdi-heart lovecolor"></i>
            </li>
          </ul>
          <h6>Store Developed</h6>
        </div>
      </div>

      <div class="col-md-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
        <div class="content text-center">
          <h1 class="mb-0">
            <countTo
              :startVal="1"
              :duration="4000"
              :endVal="1000"
            ></countTo
            >+
          </h1>
          <ul class="list-unstyled mb-0 h5">
            <li class="list-inline-item">
              <i class="mdi mdi-star my-color-1"></i>
            </li>
            <li class="list-inline-item">
              <i class="mdi mdi-star my-color-1"></i>
            </li>
            <li class="list-inline-item">
              <i class="mdi mdi-star my-color-1"></i>
            </li>
            <li class="list-inline-item">
              <i class="mdi mdi-star my-color-1"></i>
            </li>
            <li class="list-inline-item">
              <i class="mdi mdi-star my-color-1"></i>
            </li>
          </ul>
          <h6>Task Completed</h6>
        </div>
      </div>
    </div>



    <div class="row justify-ontent-right mt-4">
   
   <div class="col-12 text-center">
                       <h4>Received Accreditations</h4>
    </div>      



      <div class="col-lg-3 col-md-3 col-6 text-center py-4">
        <img
          src="images/home/lynda2.png"
          class="avatar avatar-ex-sm"
          alt=""
        />
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-3 col-6 text-center py-4">
        <img
          src="images/home/coursera.png"
          class="avatar avatar-ex-sm"
          alt=""
        />
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-3 col-6 text-center py-4">
        <img
           src="images/home/udemy.png"
          class="avatar avatar-ex-sm"
          alt=""
        />
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-3 col-6 text-center py-4">
        <img
          src="images/home/freelancer.png"
          class="avatar avatar-ex-sm"
          alt=""
        />
      </div>
      <!--end col-->


    </div>
  </div>
  <!--end col-->
</div>
<!--end row-->
            <div class="kd-custom-text-right">
      Trusted by over 100+ of the world’s leading outsourcing
      companies
    </div>
</div>
<!--end container-->




















      <div class="container pt-4 mt-60">
        <div class="row justify-content-center" id="counter">
          <div class="col-12 text-center">
            <div class="section-title">
            




              <h4 class="title mb-4  black-color-font">"Money can’t buy time;<span class="black-color-font"> unless <b>you’ve got Plan</b></span>"</h4>
     
    


              <p class="responsive-small text-muted para-desc mx-auto mb-0">
              Hire our <b>creative artists</b> and increase your <b>brand exposure</b>;<br/>people will <b>recognize your best image</b>.
              </p>


            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->



















      



      <div class="responsive-center container">
        <br>
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-6 order-1 order-md-2">
            <img src="images/explore/explore-1.png" class="img-fluid mover" alt="" />
          </div>
          <!--end col-->
          <div
            class="col-lg-6 col-md-6 mt-sm-0 pt-sm-0 order-2 order-md-1"
          >
            <div class="section-title mr-lg-5">
               <img src="images/clipart/handshake02.png" class="avatar avatar-80x" alt="">

              <h4 class="title mb-4"><span v-b-tooltip.hover title="best-experienced website development company 
                  or digital marketing company, where we come up with creative freelance 
                  web developer skill and expert digital marketing service "><b>Plan and Collaborate</b> </span>
                <br />
                On Your Content
              </h4>
              <p class="responsive-small text-muted">
                After confirmation with the plan & <b>necessary content</b>, one of our representatives will update your existing profile on our 
                <span v-b-tooltip.hover title="Karukaj DIGITAL is one of the best digital marketing agency in Bangladesh near Dhaka, 
                          where KD Members Portal is the digital agency management area of ' KD ' to offer digital marketing service and handle full operations. Get Started." class="text-muted">
                          CRM Business Portal</span> and give you access to <b>manage new tasks</b> or <b>project activities</b>.
              </p>


                     



            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
      <br>

            <div class="responsive-center container">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-6">
            <img  width="100%" height="300" 
              src="images/explore/explore2.png"
              class="img-fluid rounded-md"
              alt=""
            />


          </div>
          <!--end col-->

          <div class="col-lg-6 col-md-6 mt-sm-0 pt-sm-0">
            <div class="section-title ml-lg-5">
               <img src="images/clipart/megaphone.png" class="avatar avatar-80x" alt="">
              <h4 class="title mb-4">Get Instant <b>Task Notification </b><br>via SMS or WhatsApp</h4>
              <p class="responsive-small text-muted">                
We've integrated instant SMS and <b> WhatsApp notification</b> system in our business portal to 
maintain transparency and speed up the progress of the work.
              </p>

              <p class="mt-3 h6 my-color-1 font-weight-bold"
                >How does it 
<span v-b-tooltip.hover title="Best-experienced website development company 
                  or digital marketing company, where we come up with creative freelance 
                  web developer skill and expert digital marketing service.">work directly?</span></p>


              <ul class="responsive-small list-unstyled text-muted">
                <li>
                  <arrow-right-circle-icon
                    class="fea icon-sm my-color-1 mr-2"
                  ></arrow-right-circle-icon
                  >To inform <b>team members</b> about new tasks and info.
                </li>
                <li>
                  <arrow-right-circle-icon
                    class="fea icon-sm my-color-1 mr-2"
                  ></arrow-right-circle-icon
                  >To <b>inform client </b>about progress-bar, including bill.
                </li>
              </ul>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->






















    <div class="container mt-80">
        <div class="row justify-content-center" id="counter">
          <div class="col-12 text-center">
            <div class="section-title">
              



              <div class="watch-video">

<div
                      class="alert alert-light alert-pills "
                      role="alert"
                    >
                      <span class="content">
                        So, are you ready to hire us?</span
                      >
                    </div>

                <a
                  href="javascript: void(0);"
                  v-b-modal.modal
                  class="btn btn-icon btn-pills video-play-icon btn-primary m-1"
                  >
                  <video-icon class="icons"></video-icon>
                  </a
                >
                <span class="font-weight-bold text-uppercase small align-middle ml-1"></span>
              </div>
              <b-modal
                id="modal"
                hide-footer
                size="lg"
                header-close-variant="white"
                header-class="border-0"
                content-class="border-0"
                centered
              >
                <youtube
                  video-id="d4aijRJFOq4"
                  ref="youtube"
                  width="100%"
                  height="432"
                  :player-vars="playerVars"
                ></youtube>
              </b-modal>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->












      <div class="container mt-40 ">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title ">

              <h4 class="title mb-4">Charge Your 
                <span class="my-color-7 font-weight-bold">  Creative Inspiration </span> 
              </h4>




              <p class="text-muted para-desc mx-auto mb-0">

                Various types of 
projects have 
different 
requirements, 
so it's important 
to <b>discuss</b> 
the scope 
of work 
in advance. Still take the <b>pricing concept</b>.
                
              
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row align-items-center mt-40">
          <div class="col-12">
            <div class="">
              <b-tabs
                pills
                justified
                nav-class="d-inline-block border py-1 px-2 rounded-pill shadow"
                nav-wrapper-class="text-center"
                align="center"
              >
                <b-tab
                  title-item-class="d-inline-block"
                  title-link-class="px-3 rounded-pill"
                >
                  <template class="shadow" v-slot:title>  <i class="mdi mdi-exit-to-app"></i>  Monthly Plan </template>
                  

        <div class="row mt-lg-4 align-items-center">
          <div class="responsive-hide col-lg-5 col-md-12 text-center text-lg-left">
            <div class="section-title mb-4 mb-lg-0 pb-2 pb-lg-0">





         <div class="p-4 mt-40">

<p class="text-center text-muted   ">
  
  <img  width="80%" height="300" 

src="images/explore/explore3.png"

class="text-center img-fluid mover"
alt=""
/>
  <br><br>
  Get our flexible and comfortable <b>daily or monthly plan </b> with <b>well-documentation</b> , but in all cases  <b>proper discussion</b> is essential <b>before work</b> <span v-b-tooltip.hover title="Karukaj DIGITAL is one of the best digital marketing agency in Bangladesh near Dhaka, 
                          where Karukaj Business CRM is the digital agency management area of ' Karukaj Digital ' to offer digital marketing service and handle full operations. 
                Sign up and check our best website design price in bangladesh" class="my-color-1 "><i class="mdi mdi-chevron-right"></i></span></p>





                      </div>
























            </div>
          </div>
          <!--end col-->

          <div class="col-lg-7 col-md-12">
            <div class="ml-lg-5">
              <div class="row align-items-center">

                <div class="col-md-6 col-12 mt-4 pt-2 pt-md-4 px-md-0">
                  <div class="card pricing-rates bg-light shadow rounded border-0">
                    <div class="card-body py-5">
                      <h6 class="title font-weight-bold text-uppercase my-color-1 mb-4" >
                        Professional Plan
                      </h6>
                      <p class="h6 bdprice">IN BDT 35K</p>


                      <div class="d-flex mb-4">

                        <span class="h4 mb-0 mt-2 my-color-1">$</span>
                        <span class="price h1 mb-0">299</span>
                        <span class="h4 align-self-end mb-1">/mo</span>
                      </div>

                      <ul class="list-unstyled mb-0 pl-0">
                        <li class="h6 text-muted mb-0">
                          <span class="my-color-1 h5 mr-2"
                            ><i
                              class="uil uil-check-circle align-middle"
                            ></i></span
                          >Any 10 Days
                        </li>
                        <li class="h6 text-muted mb-0">
                          <span class="my-color-1 h5 mr-2"
                            ><i
                              class="uil uil-check-circle align-middle"
                            ></i></span
                          >2 Hours block/day
                        </li>
                        <li class="h6 text-muted mb-0">
                          <span class="my-color-1 h5 mr-2"
                            ><i
                              class="uil uil-check-circle align-middle"
                            ></i></span
                          >1/2/7 Task Equivalent
                        </li>

                        <li class="h6 text-muted mb-0">
                          <span class="my-color-1 h5 mr-2"
                            ><i
                              class="uil uil-check-circle align-middle"
                            ></i></span
                          >Premium Content
                        </li>
                        <li class="h6 text-muted mb-0">
                          <span class="my-color-1 h5 mr-2"
                            ><i
                              class="uil uil-check-circle align-middle"
                            ></i></span
                          >(Advance) Payroll
                        </li>

                        <li class="h6 text-muted mb-0">
                          <span class="my-color-1 h5 mr-2"
                            ><i
                              class="uil uil-check-circle align-middle"
                            ></i></span
                          >5 Time Revisions
                        </li>
                        
                        
                      </ul>




                      <a href="javascript: void(0);" class="btn btn-primary mt-4" v-b-modal.modal-package1>Onboard Now</a>


                      <b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-package1" size="lg" centered body-class="p-0">
        <div class="row justify-content-center align-items-center">
       
          <div class="col-lg-6 col-md-6 order-1 order-md-1">
            <p class="text-center text-muted para-desc ">Please <b>discuss</b> the scope of work in advance.</p>

          <img height="420" width="420" src="images/explore/professional.png" class="img-fluid" alt="" />
        </div>

        <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-2">
          <iframe height="420" width="380" frameborder="0" src="https://business.karukaj.com.bd/forms/wtl/9c7ff50000f43b5e721aa6e1913d2bc7"  style="border:0"></iframe>
        </div>

        </div>
        </b-modal>





                    </div>
                  </div>
                </div>
                <!--end col-->


                <div class="col-md-6 col-12 mt-4 pt-4 px-md-0">
                  <div
                    class="card pricing-rates starter-plan shadow rounded border-0"
                  >
                    <div class="card-body py-5">
                      <h6
                        class="title font-weight-bold text-uppercase my-color-1 mb-4"
                      >
                        Premium Plan

                      </h6>
                      <p class="h6 bdprice">IN BDT 55K</p>

                      <div class="d-flex mb-4">
                        <span class="h4 mb-0 mt-2">$</span>
                        <span class="price h1 mb-0">499</span>
                        <span class="h4 align-self-end mb-1">/mo</span>
                      </div>

                      <ul class="list-unstyled mb-0 pl-0">
                         <li class="h6 text-muted mb-0">
                          <span class="my-color-1 h5 mr-2"
                            ><i
                              class="uil uil-check-circle align-middle"
                            ></i></span
                          >Any 20 days
                        </li>
                        <li class="h6 text-muted mb-0">
                          <span class="my-color-1 h5 mr-2"
                            ><i
                              class="uil uil-check-circle align-middle"
                            ></i></span
                          >3 Hours block/day
                        </li>
                        <li class="h6 text-muted mb-0">
                          <span class="my-color-1 h5 mr-2"
                            ><i
                              class="uil uil-check-circle align-middle"
                            ></i></span
                          >1/5/10 Task Equivalent
                        </li>

           


                        


                        <li class="h6 text-muted mb-0">
                          <span class="my-color-1 h5 mr-2"
                            ><i
                              class="uil uil-check-circle align-middle"
                            ></i></span
                          >Within 45 Days
                        </li>

                         <li class="h6 text-muted mb-0">
                          <span class="my-color-1 h5 mr-2"
                            ><i
                              class="uil uil-check-circle align-middle"
                            ></i></span
                          >(3 Session) Payrolls
                        </li>
                        <li class="h6 text-muted mb-0">
                          <span class="my-color-1 h5 mr-2"
                            ><i
                              class="uil uil-check-circle align-middle"
                            ></i></span
                          >Unlimited Revisions
                        </li>

                      </ul>





                      <a href="javascript: void(0);" class="btn btn-primary mt-4" v-b-modal.modal-package2>Onboard Now</a>


                      <b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-package2" size="lg" centered body-class="p-0">
        <div class="row justify-content-center align-items-center">
       
          <div class="col-lg-6 col-md-6 order-1 order-md-1">
            <p class="text-center text-muted para-desc ">Please <b>discuss</b> the scope of work in advance.</p>

          <img height="420" width="420" src="images/explore/premium3.png" class="img-fluid" alt="" />
        </div>

        <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-2">
          <iframe height="420" width="380" frameborder="0" src="https://business.karukaj.com.bd/forms/wtl/9c7ff50000f43b5e721aa6e1913d2bc7"  style="border:0"></iframe>
        </div>

        </div>
        </b-modal>










                    </div>
                  </div>
                </div>
                <!--end col-->

              </div>
              <!--end row-->
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->






















                </b-tab>
                <b-tab
                  title-item-class="d-inline-block"
                  title-link-class="px-3 rounded-pill"
                >
                  <template v-slot:title>
                    <i class="mdi mdi-export"></i>  Daily Plan
                  </template>
             









                 <br>
                 <br>
                  <div class="row">
                    <div class="responsive-hide col-lg-3 col-md-6 col-12 mt-4 pt-2">
                      


                      <div class="pricing-rates bg-light py-5 p-4 rounded">

                        <h6  v-b-tooltip.hover title="Maintenance Purpose or for Quick Planner" class="my-color-1">DAILY PLAN <i class="mdi mdi-chevron-right"></i></h6>

                       
                        


           



                        <p class="responsive-small text-muted para-desc mb-0 mx-auto">
                At <span v-b-tooltip.hover title="With other digital marketing service, our website design price in Bangladesh is very affordable.
                  In addition to custom redesigning old websites, Karukaj digital marketing agency is one of the 
                  best web development companies in Bangladesh." class=" text-muted">Karukaj DIGITAL</span>
                  we work in phases, not on the basis of contract, but on the basis of advance payment or full payment.
             
                
                </p>

        
                 


                      </div>






























                    </div>
                    <!--end col-->

                    <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                      <div
                        class="card pricing-rates starter-plan bg-white shadow rounded border-0"
                      >
                        <div class="card-body py-5">
                          <h6
                            class="title text-uppercase font-weight-bold my-color-1 mb-4"
                          >
                            TRY PLAN
                          </h6>
                          <p class="h6 bdprice">IN BDT 1K</p>

                          <div class="d-flex mb-4">
                            <span class="h4 mb-0 mt-2">$</span>
                            <span class="price h1 mb-0">10</span>
                            <span class="h4 align-self-end mb-1"> / task</span>
                          </div>

                          <ul class="list-unstyled mb-0 pl-0">
                            <li class="text-muted mb-0">
                              <span class="my-color-1 h5 mr-2"
                                ><i
                                  class="uil uil-check-circle align-middle"
                                ></i></span
                              >One single task
                            </li>
                            <li class="text-muted mb-0">
                              <span class="my-color-1 h5 mr-2"
                                ><i
                                  class="uil uil-check-circle align-middle"
                                ></i></span
                              >1 day 2 hours
                            </li>
                            <li class="text-muted mb-0">
                              <span class="my-color-1 h5 mr-2"
                                ><i
                                  class="uil uil-check-circle align-middle"
                                ></i></span
                              >Free consultation
                            </li>
                          </ul>






                          <a href="javascript: void(0);" class="btn btn-primary mt-4" v-b-modal.modal-package3>Try us Now</a>


<b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-package3" size="lg" centered body-class="p-0">
<div class="row justify-content-center align-items-center">

<div class="col-lg-6 col-md-6 order-1 order-md-1">
  <p class="text-center text-muted para-desc ">Please <b>discuss</b> the scope of work in advance.</p>

<img height="420" width="420" src="images/explore/single-task.png" class="img-fluid" alt="" />
</div>

<div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-2">
<iframe height="420" width="380" frameborder="0" src="https://business.karukaj.com.bd/forms/wtl/9c7ff50000f43b5e721aa6e1913d2bc7"  style="border:0"></iframe>
</div>

</div>
</b-modal>






                        </div>
                      </div>
                    </div>
                    <!--end col-->

                    <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                      <div class="card pricing-rates bg-light rounded border-0">
                        <div class="card-body py-5">
                          <div class="ribbon ribbon-right ribbon-warning overflow-hidden"><span class="text-center d-block shadow small h6">Best</span></div>
                          <h6
                            class="title text-uppercase font-weight-bold mb-4"
                          >
                            4 DAY PLAN
                          </h6>
                          <p class="h6 bdprice">IN BDT 6K</p>
                          <div class="d-flex mb-4">
                           <span class="h4 mb-0 mt-2">$</span>
                            <span class="price h1 mb-0">67</span>
                            <span class="h4 align-self-end mb-1">/ mo</span><br>
                          

                            

                          </div>

                          <ul class="list-unstyled mb-0 pl-0">
                            <li class="text-muted mb-0">
                              <span class="my-color-1 h5 mr-2"
                                ><i
                                  class="uil uil-check-circle align-middle"
                                ></i></span
                              >Any 4 days plan
                            </li>
                            <li class="text-muted mb-0">
                              <span class="my-color-1 h5 mr-2"
                                ><i
                                  class="uil uil-check-circle align-middle"
                                ></i></span
                              >1/5 task equivalent
                            </li>
                            <li class="text-muted mb-0">
                              <span class="my-color-1 h5 mr-2"
                                ><i
                                  class="uil uil-check-circle align-middle"
                                ></i></span
                              >2 hours block/day
                            </li>
                            <li class="text-muted mb-0">
                              <span class="my-color-1 h5 mr-2"
                                ><i
                                  class="uil uil-check-circle align-middle"
                                ></i></span
                              >Premium resource
                            </li>
                          </ul>









                          <a href="javascript: void(0);" class="btn btn-primary mt-4" v-b-modal.modal-package4>Onboard Now</a>


<b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-package4" size="lg" centered body-class="p-0">
<div class="row justify-content-center align-items-center">

<div class="col-lg-6 col-md-6 order-1 order-md-1">
  <p class="text-center text-muted para-desc ">Please <b>discuss</b> the scope of work in advance.</p>

<img height="420" width="420" src="images/explore/single-task4.png" class="img-fluid" alt="" />
</div>

<div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-2">
<iframe height="420" width="380" frameborder="0" src="https://business.karukaj.com.bd/forms/wtl/9c7ff50000f43b5e721aa6e1913d2bc7"  style="border:0"></iframe>
</div>

</div>
</b-modal>
















         
                        </div>
                      </div>
                    </div>
                    <!--end col-->

                    <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                      <div class="card pricing-rates bg-light rounded border-0">
                        <div class="card-body py-5">
                          <h6
                            class="title text-uppercase font-weight-bold mb-4"
                          >
                            7 DAY PLAN
                          </h6>
                          <p class="h6 bdprice">IN BDT 10K</p>

                          <div class="d-flex mb-4">
                            <span class="h4 mb-0 mt-2">$</span>
                            <span class="price h1 mb-0">129</span>
                            <span class="h4 align-self-end mb-1">/mo</span>
                          </div>

                          <ul class="list-unstyled mb-0 pl-0">
                            <li class="text-muted mb-0">
                              <span class="my-color-1 h5 mr-2"
                                ><i
                                  class="uil uil-check-circle align-middle"
                                ></i></span
                              >Any 7 days plan
                            </li>
                            <li class="text-muted mb-0">
                              <span class="my-color-1 h5 mr-2"
                                ><i
                                  class="uil uil-check-circle align-middle"
                                ></i></span
                              >1/9 task equivalent
                            </li>
                            <li class="text-muted mb-0">
                              <span class="my-color-1 h5 mr-2"
                                ><i
                                  class="uil uil-check-circle align-middle"
                                ></i></span
                              >2 hours block/day
                            </li>
                            <li class="text-muted mb-0">
                              <span class="my-color-1 h5 mr-2"
                                ><i
                                  class="uil uil-check-circle align-middle"
                                ></i></span
                              >Instant metting
                            </li>
                                                        <li class="text-muted mb-0">
                              <span class="my-color-1 h5 mr-2"
                                ><i
                                  class="uil uil-check-circle align-middle"
                                ></i></span
                              >SOP Premium Access
                            </li>
                          </ul>




                          <a href="javascript: void(0);" class="btn btn-primary mt-4" v-b-modal.modal-package5>Onboard Now</a>


<b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-package5" size="lg" centered body-class="p-0">
<div class="row justify-content-center align-items-center">

<div class="col-lg-6 col-md-6 order-1 order-md-1">
  <p class="text-center text-muted para-desc ">Please <b>discuss</b> the scope of work in advance.</p>

<img height="420" width="420" src="images/explore/single-task10.png" class="img-fluid" alt="" />
</div>

<div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-2">
<iframe height="420" width="380" frameborder="0" src="https://business.karukaj.com.bd/forms/wtl/9c7ff50000f43b5e721aa6e1913d2bc7"  style="border:0"></iframe>
</div>

</div>
</b-modal>






                        </div>
                      </div>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->












                </b-tab>
              </b-tabs>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->





      <div class="container mt-100 mt-60">


        <div class="row">
          <div class="col-md-6 col-12">
            <div class="media">
              <help-circle-icon
                class="fea icon-ex-md my-color-1 mr-2 mt-1"
              ></help-circle-icon>
              <div class="media-body">








                <h5 class="mt-0">How to create Karukaj account?</h5>
                <p class="responsive-small answer text-muted mb-0">
                  It's like opening any email account. That means your name, email, phone number, business address, type of business, etc. 

             

                </p>


              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <div class="media">
              <help-circle-icon
                class="fea icon-ex-md my-color-1 mr-2 mt-1"
              ></help-circle-icon>
              <div class="media-body">



                  
   
                <h5 class="mt-0">
                  How do Karukaj exchange files?
                </h5>
                <p class="responsive-small answer text-muted mb-0">
                We strongly recommend to transfers file through Google Drive. However, within the <span v-b-tooltip.hover title="
                
                
                Karukaj DIGITAL, known as best eCommerce website development company in Bangladesh and our website design price in Bangladesh is very affordable.
                
            
                          
                          " class="text-muted"> Business CRM </span>, you will find certain types of file sharing options in all available places.
                </p>             
       
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-6 col-12 mt-4 pt-2">
            <div class="media">
              <help-circle-icon
                class="fea icon-ex-md my-color-1 mr-2 mt-1"
              ></help-circle-icon>
              <div class="media-body">


  <h5 class="mt-0">


                  Why do you need a Karukaj account?
                </h5>

   
                <p class="responsive-small answer text-muted mb-0">
<span v-b-tooltip.hover title="Karukaj DIGITAL is one of the best digital marketing agency in Bangladesh near Dhaka, 
                          where Karukaj Business CRM is the digital agency management area of ' Karukaj DIGITAL' to offer digital marketing service and handle full operations.">"Karukaj Business CRM"</span>
                          is used to complete each task/project in few steps, which is easier for both developers and customers. We use this management system as a tool to plan, follow up and ensure that the business is run according to established routines.</p>

            

        
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-6 col-12 mt-4 pt-2">
            <div class="media">
              <help-circle-icon
                class="fea icon-ex-md my-color-1 mr-2 mt-1"
              ></help-circle-icon>
              <div class="media-body">
                <h5 class="mt-0">Is it possible to get service without an account?</h5>
                <p class="responsive-small answer text-muted mb-0">
                  Yes. But this may be necessary. we save <span v-b-tooltip.hover title="Karukaj does outsourcing in Bangladesh. Besights, Karukaj is the most popular as the best e-commerce website development company in Bangladesh, who have any kind of custom skills as a web development company in Bangladesh. Stay around us!
                  " class="text-muted">development</span>-related information in your account for future reference, which you can get by logging in or reset by email at any time. So you can be safe and secure with our purpose and your participation.

                                
                </p>

              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->


      </div>
      <!--end container-->
























<div class="container mt-60">
  <div class="row justify-content-center" id="counter">
    <div class="col-12 text-center">
      <div class="section-title">
        <h4 class="title mb-0 ">
          <b>Actions Speak</b> Louder Than Words! 
            </h4>

        <p class="text-muted mb-0 mb-4 mx-auto para-desc">


          We prioritize project, one after one and whoever informs first will get the service first.


        </p>
    
        







      </div>
    </div>
    <!--end col-->
  </div>
  <!--end row-->
</div>
<!--end container-->

























        <div class="container mt-60">
            <div class="row align-items-center">




              <div class="col-lg-5 col-md-6 md-4 col-12 mt-sm-0 pt-2 pt-sm-0">

                <h4 class="title font-weight-bold">Confirm us now     </h4>
                

          



              <p class="text-muted mb-0">Save your time to get it up and see everything OnBoard. We believe that establishing core values ​​like honesty, openness, 
                  and fairness is the key to building a relationship. </p>


              <div class="carousel-cell mt-20">
                <img 
                  src="images/explore/explore4.png"
                  class="img-fluid mover"
                  alt=""
                />
              </div>

          <!--end col-->


          <h4 class="title font-weight-bold mt-20">Are you new to us?            
              </h4>
          <p class="text-muted mx-auto mb-4">
                Let us know by filling out this simple form.
                A dedicated expert from our system will reply you or inform you about project estimate with details. 
              </p>







              <div class="media contact-detail align-items-center mt-3">
                            <div class="icon">
                                <map-pin-icon class="fea icon-m-md text-dark mr-3"></map-pin-icon>
                            </div>
                            <div class="media-body content ">
                                 <h5 class="title font-weight-bold mb-0">Our Location</h5>

                                 7th Floor, Ka 215/B, JS Tower, Merul Badda, Dhaka, BD. <br>

                                <a href="javascript: void(0);" class="video-play-icon text-muted underline " v-b-modal.modal-1>View on Google map</a>
                            </div>
                            <b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-1" size="lg" centered body-class="p-0">
                                <iframe height="500" width="800" src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d4342.122730387992!2d90.41996321855859!3d23.773204123512432!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1643032484214!5m2!1sen!2sbd" style="border:0"></iframe>
                            </b-modal>
                        </div>
                        <br>

              <p class="text-muted font-weight-bold mb-4">You can also chat with us via our favorite channels.</p>






<ul class="list-unstyled social-icon mb-0">
    <li class="list-inline-item"><a target="_blank" href="https://www.facebook.com/karukaj.digital" class="rounded">
            <facebook-icon class="fea icon-sm fea-social"></facebook-icon>
        </a></li>
    <li class="list-inline-item ml-1"><a target="_blank" href="https://www.instagram.com/karukaj.digital" class="rounded">
            <instagram-icon class="fea icon-sm fea-social"></instagram-icon>
        </a></li>
    <li class="list-inline-item ml-1"><a target="_blank" href="https://www.twitter.com/karukaj_digital" class="rounded">
            <twitter-icon class="fea icon-sm fea-social"></twitter-icon>
        </a></li>
    <li class="list-inline-item ml-1"><a target="_blank" href="https://www.linkedin.com/company/karukajdigital/" class="rounded">
            <linkedin-icon class="fea icon-sm fea-social"></linkedin-icon>
        </a></li>
    <li class="list-inline-item ml-1"><a target="_blank" href="https://karukaj.github.io/" class="rounded">
            <github-icon class="fea icon-sm fea-social"></github-icon>
        </a></li>
    <li class="list-inline-item ml-1"><a target="_blank" href="javascript:void(0)" class="rounded">
            <youtube-icon class="fea icon-sm fea-social"></youtube-icon>
        </a></li>
    <li class="list-inline-item ml-1"><a target="_blank" href="javascript:void(0)" class="rounded">
            <gitlab-icon class="fea icon-sm fea-social"></gitlab-icon>
        </a></li>
</ul>
<!--end icon-->


            <div class="text-muted "><small>24 hours. That's all it will take you to figure out the cost benefits.</small></div>



            

                </div>






                <div class="col-lg-7 col-md-6 col-12">
                  <div class="card rounded bg-light shadow-lg border-0">
          <div class="card-body">

            <div class="title-heading">


              <h4 class="text-center font-weight-bold">
                Get a free Price Estimate
            </h4>
             
               <p class="text-center">
                Money can’t buy time;                
Unless you’ve got Plan.
               </p>
                      </div>      
                      
                      
                      <iframe width="100%" height="640" src="https://business.karukaj.com.bd/forms/quote/45e759339042747f47df23db4d26987c" frameborder="0" allowfullscreen></iframe>



                    </div>       
                    
                    

                    </div>



                </div>

          





            </div>
        </div>
        <!--end container-->







































    </section>
    <!--end section-->
    <!-- Features End -->
    <!--end section-->


    <section
      class="section mt-100 mt-100 bg-white pt-0 mt-4 pt-2"
      :style="{
        background:
          'url(' +
          require('../../public/images/real/build2.png') +
          ') bottom no-repeat',
      }"
    >
  
    <br> <br> <br> <br> <br> <br> <br>
  </section>














    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-primary btn-icon back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>






